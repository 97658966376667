<template>
    <div class="admin-settings-trnaslations">
        <!-- <b-card class="cms-pages-admin" :title="$t('Coupons')">
            <b-row>
                <b-col cols="12">
                    <good-data-table ref="couponTable" :columns="fields" :no-serial="true"
                        :total-column="'response.responseData.total'" :data-column="'response.responseData.data'"
                        :api-endpoint="'/subscription/coupons-paginate'" :button-label="$t('Generate Coupon(s)')"
                        @add-new-info="toggelModal = true" />
                </b-col>
            </b-row>
        </b-card> -->
        <b-row>
            <b-col cols="12" class="d-flex justify-content-end">
                <b-button 
                    variant="primary" 
                    :disabled="toggelModal" 
                    type="button" 
                    @click="toggelModal = !toggelModal"
                >
                    {{ $t('Import Users') }}
                </b-button>
            </b-col>
            <b-col v-if="!isEmpty(errors)" cols="12" class="mt-3">
                <b-alert :show="!isEmpty(errors)" variant="danger" class="py-1">
                    <b-col cols="12" v-for="(error, ek) in errors" :key="`error_row_${ek}`" v-html="error">
                    </b-col>
                </b-alert>
            </b-col>
        </b-row>
        
        <b-modal 
            v-model="toggelModal" 
            :no-close-on-backdrop="true" 
            :title="$t('Import Store/Providers')"
            :ok-title="$t('Start Import')" 
            ok-only 
            hide-footer 
            @ok="submitForm"
        >
            <b-row>
                <b-col cols="12">
                    <b-form @submit.prevent="submitForm">
                        <b-row class="d-flex justify-content-center">
                            <b-col cols="12">
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group label="Select User Type">
                                            <b-form-radio-group 
                                                id="radio-group-1" 
                                                v-model="userType" 
                                                :options="userTypeOptions"
                                                name="user_type"
                                                :required="true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <template>
                                        <b-col cols="12" md="7">
                                            <b-form-group :label="$t('File')" label-for="file">
                                                <b-form-file 
                                                    :disabled="isEmpty(userType)"
                                                    v-model="file" 
                                                    placeholder="Choose a file or drop it here..."
                                                    drop-placeholder="Drop file here..."
                                                    accept=".xlsx,.csv,.xls"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="5">
                                            <b-button class="mt-2" :disabled="isEmpty(importSampleUrl)" variant="primary" @click="downloadSample">
                                                Download
                                            </b-button>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-button 
                                                variant="success" 
                                                type="submit" 
                                                class="float-right"
                                                :disabled="loading || isEmpty(file)"
                                            >
                                                <template v-if="loading">{{ $t('Importing Data') }}</template>
                                                <template v-else>{{ $t('Start Import') }}</template>
                                            </b-button>
                                        </b-col>
                                    </template>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
    
<script>
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
// import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities';

const userTypeOptions = [
    { text: 'Store', value: 'store' },
    { text: 'Provider', value: 'provider' },
]

export default {
    components: {
        // GoodDataTable
    },
    data: () => ({
        toggelModal: false,
        file: null,
        userType: null,
        userTypeOptions,
        loading: false,
        errors: [],
    }),
    computed: {
        importSampleUrl: {
            get(){
                const providerFile = 'ImportProviders.xlsx';
                const storeFile = 'ImportStores.xlsx';
                const importPath = '/import/';
                const api = process.env.VUE_APP_API_BASE_END_POINT
                const url = api+importPath;
                if(this.userType == 'store'){
                    return url+storeFile
                }else if(this.userType == 'provider'){
                    return url+providerFile
                }
                return null
            }
        }
    },
    mounted() {
    },
    methods: {
        isEmpty,
        ...mapActions('importStoreProvider', [
            'importUsers',
        ]),
        async submitForm() {
            this.errors = []
            if (isEmpty(this.file)) {
                showErrorNotification(this, "Select the file first!")
                return false;
            }
            this.loading = true
            showLoader()
            const formDataObj = new FormData
            formDataObj.append('user_type', this.userType)
            formDataObj.append('import_users', this.file)
            this.importUsers(formDataObj).then(response => {
                const {status, data,} = response
                this.toggelModal = false
                this.loading = false
                if (status === 200) {
                    showSuccessNotification(this, `${data.message}`)
                }else if(status === 202){
                    if(!isEmpty(data.responseData)){
                        this.errors = data.responseData
                    }
                }
            }).catch((error) => {
                showErrorNotification(this, error)
                this.toggelModal = false
                this.loading = false
            })
            hideLoader()
        },
        downloadSample(){
            window.location.href = this.importSampleUrl
        }
    }
}
</script>
    